import { isDesktop, isMobile } from "../../common/_constants";
import { toggleSidebarHiddenVisible } from "../../furniture/_menu";
import { adjustFontScale } from "../helpers/_element-handlers";
import handleMediaQueryChange from "../helpers/_media_query";
import { debounce } from "../helpers/_observer-handler";

const adjustQuickLinksDisplay = () => {
    const plugins = document.querySelectorAll("[data-quick-links-plugin]")

    for (const plugin of plugins) {
        const container = plugin.querySelector("[data-quick-links-plugin-container]");
        if (!container) return;

        const quickLinksSidebar = plugin.querySelector("[data-quick-links-sidebar]");
        const quickLinksModal = plugin.querySelector("[data-quick-links-modal]");
        const links = Array.from(container.querySelectorAll("[data-quick-link]"));
        const moreButton = plugin.querySelector(".moreQuickLinks");

        links.forEach(link => link.classList.remove("hidden"));
        moreButton.classList.add("hidden");

        if (quickLinksSidebar.classList.contains("translate-x-0")) {
            quickLinksSidebar.classList.replace("translate-x-0", "translate-x-full");
            document.body.classList.remove("sidebar-expanded");
        }

        if (quickLinksModal.classList.contains("md:flex")) {
            quickLinksModal.classList.replace("md:flex", "md:hidden");
            document.body.classList.remove("sidebar-expanded");
        }

        if (isMobile()) {
            const rows = new Map();
            let hiddenCount = 0;

            links.forEach(link => {
                const top = link.getBoundingClientRect().top;
                rows.set(top, [...(rows.get(top) || []), link]);
            });

            const rowArray = [...rows.values()];

            if (rowArray.length > 2) {
                rowArray.slice(2).flat().forEach(link => link.classList.add("hidden"));
                hiddenCount += rowArray.slice(2).flat().length;

                const secondRow = rowArray[1];
                secondRow?.[secondRow.length - 1]?.classList.add("hidden");
                hiddenCount++;
            }

            moreButton.classList.toggle("hidden", hiddenCount === 0);
            moreButton.classList.toggle("flex", hiddenCount > 0);

            quickLinkSidebar(plugin);
        } else {
            let totalWidth = 0;
            let hiddenCount = 0;
            const containerWidth = container.clientWidth;
            const GAP_BETWEEN_LINKS = 16;
            const MORE_BUTTON_WIDTH = 72;

            links.forEach(link => {
                totalWidth += link.offsetWidth + GAP_BETWEEN_LINKS;
                if (totalWidth > containerWidth - MORE_BUTTON_WIDTH - (GAP_BETWEEN_LINKS * 2)) {
                    link.classList.add("hidden");
                    hiddenCount++;
                }
            });

            moreButton.classList.toggle("hidden", hiddenCount === 0);
            moreButton.classList.toggle("flex", hiddenCount > 0);
        }
    }
};

const quickLinksModal = () => {

    const plugins = document.querySelectorAll("[data-quick-links-plugin]")

    for (const plugin of plugins) {
        const quickLinksModal = plugin.querySelector("[data-quick-links-modal]");
        const moreQuickLinksButton = plugin.querySelector(".moreQuickLinks");
        const quickLinksCloseButton = plugin.querySelector("#quickLinksModalClose");

        if (!isDesktop() || !quickLinksModal || !moreQuickLinksButton || !quickLinksCloseButton) return;

        moreQuickLinksButton.addEventListener("click", () => {
            quickLinksModal.classList.replace("md:hidden", "md:flex");
            document.body.classList.add("sidebar-expanded");
            adjustFontScale(quickLinksModal);
        });

        quickLinksCloseButton.addEventListener("click", () => {
            quickLinksModal.classList.replace("md:flex", "md:hidden");
            document.body.classList.remove("sidebar-expanded");
        });
    }
};

const quickLinkSidebar = (plugin) => {
    const moreQuickLinks = plugin.querySelector('.moreQuickLinks')
    const quickLinkCloseButton = plugin.querySelector('.quickLinkCloseButton')
    const quickLinkSidebar = plugin.querySelector('[data-quick-links-sidebar]')

    toggleSidebarHiddenVisible(moreQuickLinks, quickLinkSidebar, null);
    toggleSidebarHiddenVisible(quickLinkCloseButton, quickLinkSidebar, null);
};

export const quickLinksPluginHandler = () => {

    const plugins = document.querySelectorAll("[data-quick-links-plugin]")

    for (const plugin of plugins) {
        window.addEventListener("resize", debounce(() => adjustFontScale(plugin)));
        adjustFontScale(plugin);
    }

    window.addEventListener("resize", debounce(() => {
        adjustQuickLinksDisplay();
        quickLinksModal();
    }));

    handleMediaQueryChange(null, quickLinksModal);
    adjustQuickLinksDisplay();
};
