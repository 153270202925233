import handleMediaQueryChange from "../helpers/_media_query";

let mobileStnLastScrollTop = 0;

const setFloatPlayerVisibility = (player, floatPlayer, currentScrollTop) => {
    if (currentScrollTop < player.offsetTop) {
        floatPlayer.style.setProperty("display", "none", "important");
    } else {
        floatPlayer.style.setProperty("display", "flex", "important");
    }
};

const setFloatPlayerTransition = (floatPlayer) => {
    floatPlayer.style.transition = "top 0.2s cubic-bezier(0.4, 0, 0.2, 1)";
};

const updateFloatPlayerStyle = (floatPlayer, topValue) => {
    floatPlayer.style.setProperty("top", topValue, "important");
};

const setMobileStnFloat = () => {
    const mainMenu = document.querySelector("#mainNavigation");
    const secondaryMenu = document.querySelector("#secondaryMenu");
    const tickerMenu = document.querySelector(
        "div#tickerNavigation:not(.hidden):not(.md\\:block)",
    );
    const stnPlayer = document.querySelector("[data-stn-player]");
    const stnRenderedPlayer = document.querySelector("#voltax-mp-video");
    const stnFloatPlayer = stnRenderedPlayer?.shadowRoot.querySelector(
        ".voltax-mp-container-sticky.floatContainer",
    );

    if (!stnFloatPlayer || !mainMenu) return;

    setFloatPlayerTransition(stnFloatPlayer);

    const { scrollY: currentScrollTop } = window;
    const navigationHeight =
        mainMenu.offsetHeight + (secondaryMenu?.offsetHeight || 0);
    const tickerHeight = tickerMenu?.offsetHeight || 0;

    if (currentScrollTop > mobileStnLastScrollTop) {
        updateFloatPlayerStyle(stnFloatPlayer, "0");

        if (currentScrollTop <= navigationHeight) {
            const adjustedTop =
                navigationHeight - currentScrollTop + tickerHeight;
            updateFloatPlayerStyle(stnFloatPlayer, `${adjustedTop}px`);
        }
    } else {
        updateFloatPlayerStyle(stnFloatPlayer, `${navigationHeight}px`);

        if (tickerMenu && currentScrollTop <= tickerHeight) {
            const adjustedTop =
                navigationHeight + (tickerHeight - currentScrollTop);
            updateFloatPlayerStyle(stnFloatPlayer, `${adjustedTop}px`);
        }
    }

    setFloatPlayerVisibility(stnPlayer, stnFloatPlayer, currentScrollTop);

    mobileStnLastScrollTop = currentScrollTop;
};

const stnScrollHandler = () => {
    window.addEventListener("scroll", setMobileStnFloat);
};

export const mobileStnFloatBehavour = () => {
    handleMediaQueryChange(stnScrollHandler, null);
};
