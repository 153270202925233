export const setEditionItemsWidth = () => {
    const editionItems = document.querySelectorAll(
        "#desktopEditionsMenu [data-edition-item]",
    );
    let longestEditionWidth = 0;

    editionItems.forEach((edition) => {
        if (edition.clientWidth > longestEditionWidth) {
            longestEditionWidth = edition.clientWidth;
        }
    });

    editionItems.forEach((edition) => {
        edition.style.width = `${longestEditionWidth}px`;
    });
};
