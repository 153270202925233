import { revcontentDesktopBottom, revcontentMobile, mobileTopMPU1Id } from '../../common/_settings'
import { isDesktop, isMobile } from '../../common/_constants'
import { getNextElementUntil } from '../helpers/_element-handlers'
import { waitForElmBySelectorIndex } from '../helpers/_observer-handler'

/* ----------------------
    Longitude Advert Containers:
*/
export const mobileTopMPU1 = () => {
    const noAdsPage = document.querySelector('.is-no-ads-page')
    if (!noAdsPage) {
        const paragraphElements = document.querySelectorAll('#article-body > div > p')
        // Check total count of paragraphs.
        // If total_count >= 4, then place ad after 4th paragraph; else place it on the last paragraph.
        const paragraphCount = paragraphElements.length
        const advertPlacement = paragraphCount >= 4 ? paragraphElements[3] : paragraphElements[paragraphCount]

        // Create Div for ad-mobileTopMpu1 Tag
        let mobileTopMpu1Container = document.createElement("div");
        mobileTopMpu1Container.classList.add("top-mpu-container");

        // Create Div for ad-mobileTopMpu1 Tag
        const mobileTopMpu1 = document.createElement('div')
        mobileTopMpu1.setAttribute('id', mobileTopMPU1Id)
        mobileTopMpu1.classList.add("sticky-advertising", "mobile-top-mpu");

        mobileTopMpu1Container.appendChild(mobileTopMpu1);

        const mobileTopMPU1Display = document.querySelector('.body-content #ad-mobileTopMpu1')

        // Place Div Tag after Fourth Paragraph of News Article
        // Only add the advert once
        if (!mobileTopMPU1Display && advertPlacement) {
            advertPlacement.parentNode.insertBefore(mobileTopMpu1Container, advertPlacement.nextSibling)
        }
    }
}

export const removeSidebarAdvert = () => {
    const sidebarAdvertisingSectiion = document.querySelector('.tsn-advertising-section')
    const articlePage = document.querySelector('.article-page')
    const noAdsPage = document.querySelector('.is-no-ads-page')

    if (articlePage && sidebarAdvertisingSectiion && !noAdsPage) {
        isMobile()
            ? (sidebarAdvertisingSectiion.style.display = 'none')
            : (sidebarAdvertisingSectiion.style.display = 'block')
    }
}

/* ----------------------
    Revcontent Advert Containers:
*/
const createScriptTagforRevcontent = () => {
    const revcontentScript = document.createElement('script')
    revcontentScript.src = 'https://assets.revcontent.com/master/delivery.js'
    revcontentScript.defer = true

    return revcontentScript
}

const revcontentDesktopBottomDatasets = () => {
    const revcontentDesktopBottomContainer = document.createElement('div')

    // Destructuring data
    const { id, rcWidget, widgetHost, endpoint, widgetId } = revcontentDesktopBottom

    // Assign data
    revcontentDesktopBottomContainer.classList.add('revcontent-desktop')
    revcontentDesktopBottomContainer.setAttribute('id', id)
    revcontentDesktopBottomContainer.dataset.rcWidget = rcWidget
    revcontentDesktopBottomContainer.dataset.widgetHost = widgetHost
    revcontentDesktopBottomContainer.dataset.endpoint = endpoint
    revcontentDesktopBottomContainer.dataset.widgetId = widgetId

    return revcontentDesktopBottomContainer
}

const revcontentMobileDatasets = () => {
    const revcontentMobileContainer = document.createElement('div')

    // Destructuring data
    const {
        id,
        rcWidget,
        widgetHost,
        endpoint,
        revMore,
        revMorePreviewHeight,
        revMoreButtonText,
        revMoreWrapperId,
        widgetId,
    } = revcontentMobile

    // Assign data
    revcontentMobileContainer.classList.add('revcontent-mobile')
    revcontentMobileContainer.setAttribute('id', id)
    revcontentMobileContainer.dataset.rcWidget = rcWidget
    revcontentMobileContainer.dataset.widgetHost = widgetHost
    revcontentMobileContainer.dataset.endpoint = endpoint
    revcontentMobileContainer.dataset.revMore = revMore
    revcontentMobileContainer.dataset.revMorePreviewHeight = revMorePreviewHeight
    revcontentMobileContainer.dataset.revMoreButtonText = revMoreButtonText
    revcontentMobileContainer.dataset.revMoreWrapperId = revMoreWrapperId
    revcontentMobileContainer.dataset.widgetId = widgetId

    return revcontentMobileContainer
}

const revcontentReadMoreHandler = (revcontentMobileDiv) => {
    const revcontentSeparator = document.querySelector('.revcontent-separator')

    waitForElmBySelectorIndex('.rev-more-unlock', 0).then((revcontentReadMoreBtn) => {
        let hiddenContents = document.querySelectorAll('.hide-article-content')
        revcontentReadMoreBtn.addEventListener('click', () => {
            if (hiddenContents.length > 0) {
                hiddenContents.forEach(content => {
                content.classList.remove('hide-article-content')
                })

                // Append mobile revcontent after authors section
                revcontentSeparator.classList.add('show')
                revcontentSeparator.after(revcontentMobileDiv)
            }
        })
    })
}

// Uncomment this if we intend to re-enable Revcontent.
// export const revcontentAttrHandler = () => {
//     const revcontentScript = createScriptTagforRevcontent()
//     const revcontentDesktopDiv = revcontentDesktopBottomDatasets()
//     const revcontentMobileDiv = revcontentMobileDatasets()
//     const revcontentSeparator = document.querySelector('.revcontent-separator')
//     const headContainer = document.querySelector('head')

//     // Display desktop and mobile revcontent
//     if (isRevcontentEnabled === "True") {
//         if (isMobile()) {
//             const mobileTopMPU1Display = document.querySelector('.top-mpu-container')
//             if (mobileTopMPU1Display) mobileTopMPU1Display.nextElementSibling.classList.add('more-article-section')

//             let articleSectionThreshold = document.querySelector('.more-article-section')
//             if (articleSectionThreshold) {
//                 articleSectionThreshold.parentNode.insertBefore(revcontentMobileDiv, articleSectionThreshold.nextSibling)

//                 for (const [index, element] of getNextElementUntil(articleSectionThreshold).entries()) {
//                     if (
//                         index !== 0 &&
//                         !element.classList.contains('more-from-tsn-section') &&
//                         !element.classList.contains('skeleton-container')
//                     ) {
//                         element.classList.add('hide-article-content')
//                     }
//                 }
//                 revcontentReadMoreHandler(revcontentMobileDiv)
//             }
//         }

//         if (isDesktop() && revcontentSeparator) {
//             revcontentSeparator.classList.add('show')
//             revcontentSeparator.parentNode.insertBefore(revcontentDesktopDiv, revcontentSeparator.nextSibling)
//         }

//         headContainer.append(revcontentScript)
//     }
// }

export const desktopTopMPU = ()=> {
    const desktopTopMpu1 = document.querySelector('.top-mpu-container')
    const desktopMPUContainer = document.querySelector('.zephr-feature_mpu-1')
    const isSectionFront = document.querySelector('body.section-page')
    const isHomepage = document.querySelector('body.homepage')
    const topNewsList = document.querySelector('.top-news__list');
    const topLeadNews = document.querySelector('.top-news__lead');

    if (!desktopTopMpu1) return

    const revertMPUToOriginalLocation = () => {
        if (desktopMPUContainer && desktopTopMpu1) {
            desktopMPUContainer.insertBefore(desktopTopMpu1, desktopMPUContainer.firstChild);
        }
    };

    const moveMPUToList = () => {
        if (topNewsList.childElementCount > 0 && desktopTopMpu1) {
            const thirdListItem = topNewsList.querySelector('.top-news__container:nth-child(3)') || topNewsList.lastElementChild;
            topNewsList.insertBefore(desktopTopMpu1, thirdListItem.nextElementSibling);
        } else {
            topLeadNews.insertAdjacentElement('afterend', desktopTopMpu1);
        }
    }

    if (isSectionFront || isHomepage) {
        if (window.matchMedia("(max-width: 768px)").matches) {
            moveMPUToList();
        } else {
            revertMPUToOriginalLocation();
        }
    }
}

export const teamPrologueAdBanner = () => {
    const teamPrologueAdContainer = document.querySelector('.team-prologue-ad');

    if (!teamPrologueAdContainer) return;

    if(isMobile()) {
        teamPrologueAdContainer.id = 'ad-topBanner1';
    } else {
        teamPrologueAdContainer.id = 'ad-midbanner';
    }
}
