// Viewport breakpoints
export const isMobile = () => {
    return window.innerWidth <= 768;
};

export const isDesktop = () => {
    return !(window.innerWidth <= 768);
};

// News page items count
// Note: Changing the count wil require to set the number of child of 'top-news__container' to hide.
export const newsCount = {
    initialItemsCount: 21,
    addItemsCount: 21,
};

export const isTemplateEnv = () => {
    const hostname = window.location.hostname;
    return (
        hostname === "c1.non-prod.ng-sportingnews.com" ||
        hostname === "local.ng-sportingnews.com" ||
        hostname === "127.0.0.1"
    );
};

export const isInViewport = (str) => {
    const elem = document.querySelector(str);
    var rect = elem.getBoundingClientRect();

    return (
        rect.bottom > 0 &&
        rect.right > 0 &&
        rect.left < (window.Width || document.documentElement.clientWidth) &&
        rect.top < (window.Height || document.documentElement.clientHeight)
    );
};

export const showElem = (e) => {
    e.classList.remove("inactive");
    e.classList.add("active");
    setTimeout(() => {
        e.classList.add("show");
    }, 100);
};

export const hideElem = (e) => {
    e.classList.remove("show");

    setTimeout(() => {
        e.classList.remove("active");
        e.classList.add("inactive");
    }, 500);
};

// Device Specific
export const isIosDevices = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
};

export const isSafari = () => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

export const updateLinkBehavior = e => {
    const hostname = window.location.hostname;
    const pattern = /(ar\.nba)|(nba\.com\/australia)|(ca\.nba)|(es\.nba)|(nba\.com\/japan)|(in\.nba)|(mx\.nba)/;
    const int = "internal";
    const ext = "external"
    const setTarget = (elem,str) => elem.setAttribute('target', str);
    const setRelation = (elem,str) => elem.setAttribute('rel', str);

    if(e.href) {

        if(e.href.match(hostname) || (!hostname.match(/(c1s2\.)|(staging\.)/) && hostname.match(/sportingnews\.com/) && (e.href.match(/sportingnews\.com/) || e.href.match(pattern)))) { // For internal links
            setTarget(e, '_self');

            if(!e.rel.match(/internal/)) {
                setRelation(e, int);
            };
        } else { // For external links
            setTarget(e, '_blank');

            if(!e.rel.match(/external/)) {
                setRelation(e, ext);
            };
        };
    };
};

export const updateLinksBehavior = () => {
    const links = [
        document.querySelectorAll('#tickerNavigation a'), // Default Ticker links
        document.querySelectorAll('#sibNbaTicker a'), // SIB NBA links
        document.querySelectorAll('footer a'), // Footer links
    ]

    links.forEach( e => {
        if(e.length > 0 ) {
            e.forEach( elm => {
                updateLinkBehavior(elm);
            })
        };
    });
};
