export const tvListingPlugin = () => {
    const tvlistingContainer = document.querySelector("st-tvlistings");

    if (tvlistingContainer) {
        let competitionDropdown = document.querySelector(
            ".competition-dropdown",
        );
        let competitionList = document.querySelector(".competition-list");
        let taxonomyContainer = document.querySelector(
            ".default-taxonomy-container",
        );
        let arrowIconUp = document.querySelector(".arrow-icon-up");
        let arrowIconDown = document.querySelector(".arrow-icon-down");

        if (competitionDropdown) {
            taxonomyContainer.addEventListener("click", function (event) {
                event.preventDefault(); // Prevent the default link behavior
                taxonomyContainer.classList.remove("bg-l2", "border-l1");
                taxonomyContainer.classList.add(
                    "border-d2",
                    "bg-black",
                    "text-white",
                );

                arrowIconDown.classList.replace("block", "hidden");
                arrowIconUp.classList.replace("hidden", "block");

                competitionList.classList.remove("hidden");
                competitionList.classList.add("flex", "flex-col");
            });

            let defaultTaxonomyName =
                document.getElementById("default-taxonomy");
            const options = competitionList.querySelectorAll("a");

            options.forEach((option) => {
                option.addEventListener("click", function (event) {
                    event.preventDefault(); // Prevent the default link behavior
                    // Get the data attributes of the clicked option
                    const id = option.getAttribute("data-id");
                    const name = option.getAttribute("data-name");

                    tvlistingContainer.setAttribute("taxonomy-entry-id", id);
                    defaultTaxonomyName.innerHTML = name;

                    taxonomyContainer.classList.add("bg-l2", "border-l1");
                    taxonomyContainer.classList.remove(
                        "border-d2",
                        "bg-black",
                        "text-white",
                    );

                    arrowIconDown.classList.replace("hidden", "block");
                    arrowIconUp.classList.replace("block", "hidden");

                    competitionList.classList.add("hidden");
                    competitionList.classList.remove("flex", "flex-col");
                });
            });

            // Close the dropdown if the user clicks outside of it
            document.addEventListener("click", function (event) {
                if (
                    !competitionDropdown.contains(event.target) &&
                    taxonomyContainer.classList.contains("bg-black")
                ) {
                    taxonomyContainer.classList.add("bg-l2", "border-l1");
                    taxonomyContainer.classList.remove(
                        "border-d2",
                        "bg-black",
                        "text-white",
                    );
                    arrowIconDown.classList.replace("hidden", "block");
                    arrowIconUp.classList.replace("block", "hidden");
                    competitionList.classList.add("hidden");
                    competitionList.classList.remove("flex", "flex-col");
                }
            });
        }
    }
};
