/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-undef */
import {
    addTextStyle,
    initialCheckedHandler,
    refreshDraftPage,
    setScrollBehaviour,
} from "./components/draft_pages/_tracker";
/* Start importing separated js files */
import {
    condensedMobileNavigationBar,
    condensedStateDesktopLogo,
    expandMobileEditionMenu,
    expandMobileSidebarMenu,
    hoverStateDesktopSportMenu,
    nbaTickerHoverHandler,
    overflowDetector,
    toggleMobileSubMenuLogo,
    updatePrimaryNavDropdownPosition,
    updateSubNavItemStyles,
    updateTickerNavDropdownPosition,
} from "./furniture/_menu";
import {
    debounce,
    lazyLoadHandler,
    lazyLoadTweetsHandler,
    loadMoreItemsHandler,
    removeServiceWorker,
} from "./components/helpers/_observer-handler";
import {
    desktopTopMPU,
    mobileTopMPU1,
    removeSidebarAdvert,
    teamPrologueAdBanner,
} from "./components/adverts/_advertising-containers";
import {
    fixtureCricketSelectClickHandle,
    hideDropdownListHandle,
    leaderboardGolfSelectClickHandle,
    optaDropdownsHandler,
    scheduleGolfSelectClickHandle,
    scheduleTennisSelectClickHandle,
    scoreboardTennisSelectClickHandle,
    scorecardCricketSelectClickHandle,
    standingCricketSelectClickHandle,
} from "./components/opta/_dropdowns";
import {
    gallerySlider,
    partnerContentSlider,
    relatedNewsSlider,
    glideConfig,
} from "./components/gallery/_glide-slider";
import { isMobile, newsCount, updateLinksBehavior } from "./common/_constants";
import {
    jumpToLatestNewsHandler,
    previousWinnersHandler,
} from "./components/march_madness/_navigation-handlers";
import {
    leagueDropdownToggleHandler,
    leagueNewSelectedHandler,
} from "./components/metabet/_league";
import {
    metabetRedirectionHandler,
    scheduleTeamLinkHandler,
    scoresTeamLinkHandler,
} from "./components/metabet/_redirection";
import { keyboardNavigation } from "./components/navigation/keyboard";
import { mouseNavigation } from "./components/navigation/mouse";
import {
    nbaRedirection,
    nbaSetDefaultEdition,
} from "./components/sib/_global_menu";
import {
    newRelatedArticlesHandler,
    relatedArticlesHandler,
} from "./components/article/_related-news";
import {
    overflowFilterHandler,
    roundNavigationHandler,
} from "./components/draft_pages/_filter-dropdown";
import {
    publishedLongDateDifference,
    publishedShortDateDifference,
    updateListingPublishedFormats,
} from "./components/article/_published-date";
import {
    seasonsFilterHandler,
    teamsFilterHandler,
} from "./components/helpers/_filters-handlers";
import {
    teamLeadArticleHeight,
    teamNameHandler,
} from "./components/helpers/_team-name-handler";

import { accordionFaqsHandler } from "./components/draft_pages/_accordion-faqs";
import { accordionTeamDetailsHandler } from "./components/draft_pages/_mock-draft";
import { authorsImageAndTextHandler } from "./furniture/_author";
import { boxingWidget } from "./components/sportransact/boxing-widget";
import { countElements } from "./components/helpers/_element-handlers";
import { descendingOrderHandler } from "./components/slidelist/_slides-numbering";
import { fetchMoreNews } from "./components/article/_more-news";
import { navigateToPage } from "./components/helpers/_navigate-handler";
import { previousYearHandler } from "./components/draft_pages/_previous-draft-picks";
import { rebuildTables } from "./components/article/_tables";
import { scrollToTargetElement } from "./components/draft_pages/_sidebar-panel";
import {
    setEditionItemsWidth,
} from "./furniture/_edition";
import { updateGridColumn } from "./components/metabet/_betting";
import { waitForElmBySelectorIndex } from "./components/helpers/_observer-handler";
import { outbrainThumbnailsLazyloader } from "./components/adverts/_outbrain-lazyloader";
import { setFooterSpacing } from "./furniture/_footer";
import { mobileStnFloatBehavour } from "./components/video_player/stn";
import { tvListingPlugin } from "./components/sportransact/tv-listings-plugin";
import { quickLinksPluginHandler } from "./components/tv_listing/_quick-links";

// Structure from previous js

removeServiceWorker();

const oneTrustModal = () => {
    const privacyTitles = [
        "Privacy Preference Center",
        "Central de preferencias de privacidad",
        "プライバシー設定",
        "개인 정보 보호 선호 센터",
        "Preferências de privacidade",
        "ศูนย์การตั้งค่าความเป็นส่วนตัว",
        "Tuỳ chọn cài đặt riêng tư",
        "Privacy Preferences",
    ];

    const setPrivacyPreference = (menuItems) => {
        if (!menuItems || menuItems.length < 1) return;

        menuItems.forEach((menuItem) => {
            if (privacyTitles.includes(menuItem.textContent)) {
                menuItem.setAttribute(
                    "data-privacy-preference-center",
                    "privacy-preference-center",
                );
                menuItem.removeAttribute("href");
            }
        });
    };

    const footerMainMenu = document.querySelectorAll(
        "#new-footer-layout .menu_list a",
    );
    const sidebarFooterMenu = document.querySelectorAll(
        "#sidebarMenu #footerMenuOnMobileSidebar a",
    );

    setPrivacyPreference(footerMainMenu);
    setPrivacyPreference(sidebarFooterMenu);

    // Set the onclick behavior
    waitForElmBySelectorIndex("div#ot-sdk-btn-floating button", 0).then(() => {
        const privacyPreferenceCenter = document.querySelectorAll(
            "[data-privacy-preference-center]",
        );
        const privacyPreferenceShieldBtn = document.querySelector(
            "div#ot-sdk-btn-floating button",
        );

        if (privacyPreferenceCenter.length < 1) return;

        privacyPreferenceCenter.forEach((button) => {
            button.addEventListener("click", () => {
                privacyPreferenceShieldBtn.click();
            });
        });
    });
};

const mobileNavigationHandlers = () => {
    const navigation = document.querySelector("#mainNavigation");
    if (navigation) {
        expandMobileSidebarMenu();
        expandMobileEditionMenu();
        toggleMobileSubMenuLogo();
        nbaTickerHoverHandler();
        condensedMobileNavigationBar();
    }
};

const footerHandlers = () => {
    setFooterSpacing();
};

const desktopNavigationHandlers = () => {
    const navigation = document.querySelector("#mainNavigation");
    if (navigation) {
        keyboardNavigation();
        mouseNavigation();
        hoverStateDesktopSportMenu();
        condensedStateDesktopLogo();
        updateSubNavItemStyles();
        updatePrimaryNavDropdownPosition();
        updateTickerNavDropdownPosition();
        nbaTickerHoverHandler();
        overflowDetector();
        setEditionItemsWidth();
    }
};

const mobileAndDesktopHandlers = () => {
    updateLinksBehavior();
    desktopTopMPU();
};

const draftPageHandlers = () => {
    const landingDraftPage = document.querySelector(".drafts-landing-page");
    const teamByTeamPage = document.querySelector(".drafts-team-by-team-page");
    const mockDraftPage = document.querySelector(".mock-draft-page");
    const roundByRoundPage = document.querySelector(
        ".drafts-round-by-round-page",
    );

    if (landingDraftPage) {
        previousYearHandler();
        navigateToPage();
        addTextStyle();
        initialCheckedHandler();
        setScrollBehaviour();
        accordionFaqsHandler();
        scrollToTargetElement();
        refreshDraftPage();
    }

    if (teamByTeamPage || roundByRoundPage) {
        seasonsFilterHandler();
        teamsFilterHandler();
        accordionTeamDetailsHandler();
        roundNavigationHandler();
        overflowFilterHandler();
    }

    if (mockDraftPage) {
        seasonsFilterHandler();
        teamsFilterHandler();
        accordionTeamDetailsHandler();
    }
};

const articlePageHandlers = () => {
    const articlePage = document.querySelector(".article-page");
    const slideRelatedNewsEnabled = document.querySelector(
        ".related-articles-container.slide-enabled",
    );
    if (articlePage) {
        // Place videoPlayerHandler after second paragraph of article
        const articleParagraphs = articlePage.querySelectorAll(
            "#article-body > div > p",
        );
        const articleParagraphCount = articleParagraphs.length;
        const videoContainer = document.querySelector(
            ".zephr-feature_video-player",
        );

        if (videoContainer && articleParagraphCount >= 2) {
            articleParagraphs[1].after(videoContainer);
        }

        if (isMobile()) {
            removeSidebarAdvert();
            mobileTopMPU1();
        }

        if (slideRelatedNewsEnabled) {
            relatedNewsSlider();
        } else {
            relatedArticlesHandler("#article-body > div > p");
        }
        // Uncomment this if we intend to re-enable Revcontent.
        // revcontentAttrHandler()
        rebuildTables();
    }
};

const newArticlePageHandlers = () => {
    const articlePage = document.querySelector(".article-page-new");
    const slideRelatedNewsEnabled = document.querySelector(
        "#article-related-article-block.slide-enabled",
    );
    if (articlePage) {
        // Place videoPlayerHandler after second paragraph of article
        const articleParagraphs = articlePage.querySelectorAll(
            "#article-body > div > p",
        );
        const articleParagraphCount = articleParagraphs.length;
        const videoContainer = document.querySelector(
            ".zephr-feature_video-player",
        );

        if (videoContainer && articleParagraphCount >= 2) {
            articleParagraphs[1].after(videoContainer);
        }

        if (isMobile()) {
            mobileTopMPU1();
        }

        if (slideRelatedNewsEnabled) {
            relatedNewsSlider();
        } else {
            newRelatedArticlesHandler("#article-body > div > p");
        }
        // Outbrain Lazyloader
        outbrainThumbnailsLazyloader();

        rebuildTables();
    }
};

const slidelistPageHandlers = () => {
    const slidelistPage = document.querySelector(".slidelist-page");
    if (slidelistPage) {
        countElements(".slide-container", ".total-slides .count");
        authorsImageAndTextHandler();
        descendingOrderHandler();
        relatedArticlesHandler(".slide-container");
    }
};

const galleryPageHandlers = () => {
    const galleryPage = document.querySelector(".gallery-page");
    if (galleryPage) {
        gallerySlider();
        countElements(".glide__slide", ".total-photos .count");
        relatedArticlesHandler("#gallery-body > p");
    }
};

const commercialPageHandlers = () => {
    const commercialPage = document.querySelector(".commercial-page");
    if (commercialPage) {
        relatedArticlesHandler("#commercial-body > p");
    }
};

const metabetPageHandlers = () => {
    const metabetPage = document.querySelector(".metabet-page");
    const isBettingOdds = document.querySelector(
        "[data-page-type='betting-data-page-odds'] ",
    );
    if (metabetPage) {
        leagueDropdownToggleHandler();
        leagueNewSelectedHandler();
        metabetRedirectionHandler();
        scoresTeamLinkHandler();
        scheduleTeamLinkHandler();
    }

    if (isBettingOdds) {
        updateGridColumn();
    }
};

const optaPageHandlers = () => {
    const optaPage = document.querySelector(".opta-page");
    if (optaPage) {
        hideDropdownListHandle();
        scorecardCricketSelectClickHandle();
        standingCricketSelectClickHandle();
        fixtureCricketSelectClickHandle();
        leaderboardGolfSelectClickHandle();
        scheduleGolfSelectClickHandle();
        scoreboardTennisSelectClickHandle();
        scheduleTennisSelectClickHandle();
        fetchMoreNews();
        optaDropdownsHandler();
    }
};

const sibPageHandlers = () => {
    const sibPage = document.querySelector("#sibNbaTicker");
    if (sibPage) {
        nbaSetDefaultEdition();
        nbaRedirection();

        // Fallback for SIB script undefined variable
        window.b = window.b || {};
        b.dom = b.dom || { title: document.title };
    }
};

const pagesHandlers = () => {
    const articlePage = document.querySelector(".article-page");
    const articlePageNew = document.querySelector(".article-page-new");
    const slidelistPage = document.querySelector(".slidelist-page");
    const galleryPage = document.querySelector(".gallery-page");
    const commercialPage = document.querySelector(".commercial-page");
    const newsPage = document.querySelector(".news-page");
    const taxonomyPage = document.querySelector(".taxonomy-page");
    const authorPage = document.querySelector(".author-page");

    if (slidelistPage || articlePage || galleryPage || commercialPage) {
        const loadInitialResource = ".skeleton-container";
        const moreNewsImgs =
            ".more-from-tsn-section__body .list-item a .list-item__img";
        const listItem = ".list-item";
        const unsetlistItem = "hide";

        // Display published short date
        publishedShortDateDifference();

        // Lazy load twitter tweets
        lazyLoadTweetsHandler();

        // Lazy load articles image
        lazyLoadHandler(
            loadInitialResource,
            moreNewsImgs,
            listItem,
            unsetlistItem,
        );

        // Authors Section
        authorsImageAndTextHandler();

        // More From Sporting News Sections
        fetchMoreNews();
    }

    if (newsPage || authorPage || taxonomyPage) {
        const { initialItemsCount, addItemsCount } = newsCount;
        const loadInitialResource = ".skeleton-container";
        const loadArticle = ".news-section__list .list-item a .list-item__img";
        const listItem = ".list-item";
        const unsetlistItem = "hide";
        const loadMoreButton = document.querySelector(".pager__item button");
        const itemsContainer = [
            ...document.querySelectorAll(".news-section__container"),
        ];

        // Handles load more articles and lazy load articles image
        loadMoreItemsHandler(
            itemsContainer,
            initialItemsCount,
            addItemsCount,
            loadMoreButton,
        );
        lazyLoadHandler(
            loadInitialResource,
            loadArticle,
            listItem,
            unsetlistItem,
        );
    }

    if (articlePageNew) {
        const loadInitialResource = ".skeleton-container";
        const moreNewsImgs =
            ".more-from-tsn-section__body .list-item a .list-item__img";
        const listItem = ".list-item";
        const unsetlistItem = "hide";

        // Display published long date
        publishedLongDateDifference();

        // Lazy load twitter tweets
        lazyLoadTweetsHandler();

        // Lazy load articles image
        lazyLoadHandler(
            loadInitialResource,
            moreNewsImgs,
            listItem,
            unsetlistItem,
        );

        // More From Sporting News Sections
        fetchMoreNews();
    }
};

const pushAffiliateWidgetImpressions = () => {
    window.dataLayer = window.dataLayer || [];
    window.widgetImpressionEvents = window.widgetImpressionEvents || [];
    window.dataLayer.push(...window.widgetImpressionEvents);
};

const marchMadnessHandlers = () => {
    const isMarchMadnessPage = document.querySelector(".march-madness-page");

    if (isMarchMadnessPage) {
        previousWinnersHandler();
        jumpToLatestNewsHandler();
    }
};

const listingPageHandlers = () => {
    const isListingPage = document.querySelector("[data-page-type='listing']");
    if (isListingPage) {
        listingPartnersContentHandler();
        updateListingPublishedFormats();
        teamListPanelHandler();
    }
};

const teamProloguePageHandlers = () => {
    const isteamProloguePage = document.querySelector(
        "[data-page-type='team-prologue']",
    );
    if (isteamProloguePage) {
        teamNameHandler();
        teamProloguePartnersContentHandler();
        updateListingPublishedFormats();
        teamLeadArticleHeight();
    }
};

const watchPageHandlers = () => {
    const isWatchPage = document.querySelector("[data-page-type='watch-page']");

    if (isWatchPage) {
        watchPagePartnersContentHandler();
        updateListingPublishedFormats();
    }
};

const homePageHandlers = () => {
    const isHomePage = document.querySelector("[data-page-type='homepage']");

    if (isHomePage) {
        updateListingPublishedFormats();
        homepagePartnerContentHandler();
    }
};

const displayDateTime = () => {
    const datetimeContainer = document.querySelector(
        "[data-testid='text--published-date']",
    );

    if (datetimeContainer) {
        updateListingPublishedFormats();
    }
};

const authorPageHandlers = () => {
    const isAuthorPageType1 = document.querySelector(
        "[data-page-type='author-type-1']",
    );
    const isAuthorPageType2 = document.querySelector(
        "[data-page-type='author-type-2']",
    );

    if (isAuthorPageType1 || isAuthorPageType2) {
        updateListingPublishedFormats();
    }
};

const videoWatchPageHandlers = () => {
    const isVideoWatchPage = document.querySelector(
        "[data-page-type='video-watch-page']",
    );

    if (isVideoWatchPage) {
        videoWatchPagePartnersContentHandler();
        updateListingPublishedFormats();
    }
};

const glideHandler = () => {
    const glideElements = document.querySelectorAll(".glide");

    glideElements.forEach((glideElement) => {
        const isSNNowSlider =
            glideElement.getAttribute("data-plugin-type") == "sn-now";

        if (isSNNowSlider) {
            glideConfig(glideElement, {
                type: "slider",
                focusAt: 0,
                startAt: 0,
                perView: 2,
                keyboard: true,
                rewind: false,
                peek: 0,
                gap: 12,
                bound: true,
            });
        } else {
            glideConfig(glideElement, {
                type: "slider",
                touchAngle: 45,
                focusAt: 0,
                startAt: 0,
                keyboard: true,
                gap: 10,
                rewind: false,
            });
        }
    });
};

const listingPartnersContentHandler = () => {
    document.querySelector("[data-page-type='listing']") &&
        partnerContentSlider();
};

const teamProloguePartnersContentHandler = () => {
    document.querySelector("[data-page-type='team-prologue']") &&
        (teamPrologueAdBanner(), partnerContentSlider());
};

const watchPagePartnersContentHandler = () => {
    document.querySelector("[data-page-type='watch-page']") &&
        partnerContentSlider();
};

const homepagePartnerContentHandler = () => {
    document.querySelector("[data-page-type='homepage']") &&
        partnerContentSlider();
};

const videoWatchPagePartnersContentHandler = () => {
    document.querySelector("[data-page-type='video-watch-page']") &&
        partnerContentSlider();
};

const updateTeamListPanelButtons = (event) => {
    event.stopPropagation();
    const teamButtons = document.querySelector(
        "#accordionToggleButton > div:first-child",
    );
    const teamListContainer = document.querySelector("#teamListPanel");

    let showButton = 0;
    let hideButton = 0;

    if (teamButtons?.children[0]?.classList?.value.match(/active/)) {
        showButton = 0;
        hideButton = 1;
        teamListContainer?.classList.add("expand");
    } else {
        showButton = 1;
        hideButton = 0;
        teamListContainer?.classList?.remove("expand");
    }

    teamButtons.children[showButton].classList.remove("visible");
    teamButtons.children[showButton].classList.remove("active");
    teamButtons.children[hideButton].classList.add("active");
    teamButtons.children[hideButton].classList.add("visible");
};

const teamListPanelHandler = () => {
    const accordionToggleButton = document.querySelector(
        "#accordionToggleButton",
    );

    if (accordionToggleButton) {
        accordionToggleButton.addEventListener("click", (event) =>
            updateTeamListPanelButtons(event),
        );
    }
};

const stnPlayerHandlers = () => {
    mobileStnFloatBehavour();
};

const tvListingPageHandlers = () => {
    quickLinksPluginHandler();
}

const functionsToRun = [
    () => glideHandler(),

    () => oneTrustModal(),

    () => mobileNavigationHandlers(),

    () => desktopNavigationHandlers(),

    () => mobileAndDesktopHandlers(),

    () => sibPageHandlers(),

    () => footerHandlers(),

    () => stnPlayerHandlers(),

    () => draftPageHandlers(),

    () => pagesHandlers(),

    () => articlePageHandlers(),

    () => newArticlePageHandlers(),

    () => slidelistPageHandlers(),

    () => galleryPageHandlers(),

    () => commercialPageHandlers(),

    () => metabetPageHandlers(),

    () => optaPageHandlers(),

    () => boxingWidget(),

    () => marchMadnessHandlers(),

    () => listingPageHandlers(),

    () => teamProloguePageHandlers(),

    () => watchPageHandlers(),

    () => homePageHandlers(),

    () => authorPageHandlers(),

    () => videoWatchPageHandlers(),

    () => tvListingPageHandlers(),

    () => displayDateTime(),

    () => tvListingPlugin(),
];

function initializeFunctions() {
    functionsToRun.forEach((func, index) => {
        try {
            func();
        } catch (error) {
            console.error(`Error in function at index ${index}:`, error);
        }
    });
}

if (document.readyState === "loading") {
    document.addEventListener("DOMContentLoaded", initializeFunctions);
} else {
    initializeFunctions();
}

// BBCode widget tracking
window.addEventListener("load", () => {
    window.widgetImpressionEvents = window.widgetImpressionEvents || [];

    let widgetLinks = document.querySelectorAll("a[data-cta-id]");
    widgetLinks.forEach((widgetLink) => {
        // Impression tracking
        widgetImpressionEvents.push({
            event: "cta_impression",
            cta_type: widgetLink.getAttribute("data-cta-type"),
            cta_id: widgetLink.getAttribute("data-cta-id"),
        });

        // Click tracking
        widgetLink.setAttribute(
            "onclick",
            `dataLayer.push({
            event: 'element_click',
            item_type: 'affiliate link',
            item_id: '` +
                widgetLink.getAttribute("href") +
                `',
            item_name: '` +
                widgetLink.getAttribute("data-item-name") +
                `',
            cta_type: '` +
                widgetLink.getAttribute("data-cta-type") +
                `',
            cta_id: '` +
                widgetLink.getAttribute("data-cta-id") +
                `'
            });`,
        );
    });

    // Push impressions to dataLayer
    pushAffiliateWidgetImpressions();
});

window.addEventListener("resize", () => {
    setEditionItemsWidth();
    removeSidebarAdvert();
    desktopTopMPU();
    listingPartnersContentHandler();
    teamProloguePartnersContentHandler();
    watchPagePartnersContentHandler();
    homepagePartnerContentHandler();
    videoWatchPagePartnersContentHandler();
});
